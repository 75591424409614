  <template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row v-if="pageData !== null">
      <b-col cols="12">
        <b-card>
          <b-row class="mx-auto">
            <div class="col-4 d-flex flex-column justify-start">
              <b-media class="mb-1" style="height: max-content!important;">
                <template v-if="getPieceByPriority(1)">
                  <b-avatar
                      class="cursor-pointer w-100 my-0"
                      ref="previewEl"
                      :src="[getPieceByPriority(1).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(1).fileData.base64 : '']"
                      size="700px"
                      rounded
                      v-b-modal.modal-upload
                      @click.native="openModal(getPieceByPriority(1))"
                  >
                    <feather-icon v-if="getPieceByPriority(1).fileData === null" icon="PlusSquareIcon" size="100"/>
                  </b-avatar>
                </template>
              </b-media>
            </div>

            <div class="col-5 d-flex flex-column justify-start">
              <div class="d-flex flex-row justify-content-between">
                <b-media class="mb-1">
                  <template v-if="getPieceByPriority(2)">
                    <b-avatar
                        style="width: 300px;"
                        class="cursor-pointer my-0"
                        ref="previewEl"
                        :src="[getPieceByPriority(2).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(2).fileData.base64 : '' ]"
                        size="200px"
                        rounded
                        v-b-modal.modal-upload
                        @click.native="openModal(getPieceByPriority(2))"
                    >
                      <feather-icon v-if="getPieceByPriority(2).fileData === null" icon="PlusSquareIcon" size="100"/>
                    </b-avatar>
                  </template>
                </b-media>
                <b-media class="mb-1">
                  <template v-if="getPieceByPriority(3)">
                    <b-avatar
                        style="width: 300px;"
                        class="cursor-pointer my-0"
                        ref="previewEl"
                        :src="[getPieceByPriority(3).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(3).fileData.base64 : '' ]"
                        size="200px"
                        rounded
                        v-b-modal.modal-upload
                        @click.native="openModal(getPieceByPriority(3))"
                    >
                      <feather-icon v-if="getPieceByPriority(3).fileData === null" icon="PlusSquareIcon" size="100"/>
                    </b-avatar>
                  </template>
                </b-media>
              </div>
              <b-media class="mb-1 w-100" style="height: max-content!important;">
                <template v-if="getPieceByPriority(4)">
                  <b-avatar
                      class="cursor-pointer w-100 my-0"
                      ref="previewEl"
                      :src="[getPieceByPriority(4).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(4).fileData.base64 : '' ]"
                      size="500px"
                      rounded
                      v-b-modal.modal-upload
                      @click.native="openModal(getPieceByPriority(4))"
                  >
                    <feather-icon v-if="getPieceByPriority(4).fileData === null" icon="PlusSquareIcon" size="100"/>
                  </b-avatar>
                </template>
              </b-media>
            </div>

          </b-row>
        </b-card>

        <b-card>
          <b-row class="mx-auto">
            <div class="col-5 d-flex flex-column justify-start">
              <b-media class="mb-1 w-100" style="height: max-content!important;">
                <template v-if="getPieceByPriority(5)">
                  <b-avatar
                      class="cursor-pointer w-100 my-0"
                      ref="previewEl"
                      :src="[getPieceByPriority(5).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(5).fileData.base64 : '' ]"
                      size="500px"
                      rounded
                      v-b-modal.modal-upload
                      @click.native="openModal(getPieceByPriority(5))"
                  >
                    <feather-icon v-if="getPieceByPriority(5).fileData === null" icon="PlusSquareIcon" size="100"/>
                  </b-avatar>
                </template>
              </b-media>
              <div class="d-flex flex-row justify-content-between">
                <b-media class="mb-1">
                  <template v-if="getPieceByPriority(6)">
                    <b-avatar
                        style="width: 300px;"
                        class="cursor-pointer my-0"
                        ref="previewEl"
                        :src="[getPieceByPriority(6).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(6).fileData.base64 : '' ]"
                        size="200px"
                        rounded
                        v-b-modal.modal-upload
                        @click.native="openModal(getPieceByPriority(6))"
                    >
                      <feather-icon v-if="getPieceByPriority(6).fileData === null" icon="PlusSquareIcon" size="100"/>
                    </b-avatar>
                  </template>
                </b-media>
                <b-media class="mb-1">
                  <template v-if="getPieceByPriority(7)">
                    <b-avatar
                        style="width: 300px;"
                        class="cursor-pointer my-0"
                        ref="previewEl"
                        :src="[getPieceByPriority(7).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(7).fileData.base64 : '' ]"
                        size="200px"
                        rounded
                        v-b-modal.modal-upload
                        @click.native="openModal(getPieceByPriority(7))"
                    >
                      <feather-icon v-if="getPieceByPriority(7).fileData === null" icon="PlusSquareIcon" size="100"/>
                    </b-avatar>
                  </template>
                </b-media>
              </div>
            </div>

            <div class="col-4 d-flex flex-column justify-start">
              <b-media class="mb-1" style="height: max-content!important;">
                <template v-if="getPieceByPriority(8)">
                  <b-avatar
                      class="cursor-pointer w-100 my-0"
                      ref="previewEl"
                      :src="[getPieceByPriority(8).fileData !== null ? baseUrl+'media/gallery/pageImage/'+getPieceByPriority(8).fileData.base64 : '']"
                      size="700px"
                      rounded
                      v-b-modal.modal-upload
                      @click.native="openModal(getPieceByPriority(8))"
                  >
                    <feather-icon v-if="getPieceByPriority(8).fileData === null" icon="PlusSquareIcon" size="100"/>
                  </b-avatar>
                </template>
              </b-media>
            </div>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--  Upload Pic Modal   -->
    <b-modal
        v-if="showUploadModal"
        id="modal-upload"
        centered
        title="بارگذاری تغییرات"
        ok-title="ثبت"
        no-close-on-backdrop
        cancelTitle="انصراف"
        @cancel="closeUploadModal"
        @ok="updatePageElement"
    >

      <b-media class="my-2 col-12 mx-auto">
        <template>
          <b-avatar
              class="w-100 cursor-pointer mx-auto"
              ref="previewEl"
              :src="[base64ImageSrc !== null ? base64ImageSrc : '']"
              size="300px"
              rounded
              @click.native="$refs.refInputEl1.click()"
          >
            <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
          </b-avatar>
        </template>
        <div class="d-none flex-wrap mt-1">
          <b-button
              variant="primary"
          >
            <input
                ref="refInputEl1"
                type="file"
                class="d-none"
                accept="image/*"
                @input="makeBase64Pic($event)"
            >
            <span class="d-none d-sm-inline">+</span>
            <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>

      <b-input-group class="input-group-merge form-send-message my-1">
        <b-form-input
            v-model="modalData.title"
            placeholder="عنوان"
        />
      </b-input-group>

      <b-input-group class="input-group-merge form-send-message my-1">
        <b-form-input
            v-model="modalData.link"
            placeholder="لینک"
        />
      </b-input-group>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
  BInputGroup,
  BModal,
} from 'bootstrap-vue'
// import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {AccountGetSliderRequest, AccountSetSliderRequest} from "@/libs/Api/Account";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
import {PageElementGetPageElementByPageIdRequest, PageElementUpdateRequest} from "@/libs/Api/PageElement";
import Helper from "@/libs/Helper";

export default {
  title: "ویرایش مجله - پنل ادمین انزا مد",
  name: "Magazine",
  data() {
    return {
      baseUrl:Helper.baseUrl,
      pageData:null,
      overlay: false,
      showUploadModal:false,
      selectedPiece:null,
      images: null,
      base64ImageSrc: null,
      modalData:null,
    }
  },
  async created() {
    await this.getPageElement();
  },
  methods: {
    openModal(data){
      let _this = this;

      _this.showUploadModal = true;
      _this.modalData = data;
      if (_this.modalData.fileData !== null){
        _this.base64ImageSrc = _this.baseUrl+'media/gallery/pageImage/'+_this.modalData.fileData.base64;
        _this.modalData.fileData = null;
      }else {
        _this.base64ImageSrc = null
      }
    },
    async getPageElement(){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:0,
        count:0,
        pageId:2
      };

      let pageElementGetPageElementByPageIdRequest = new PageElementGetPageElementByPageIdRequest(_this);
      pageElementGetPageElementByPageIdRequest.setParams(data);
      await pageElementGetPageElementByPageIdRequest.fetch(function (content){
        _this.overlay = false;
        _this.pageData = content.elements;

      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async updatePageElement(){
      let _this = this;
      _this.overlay=true;

      let pageElementUpdateRequest = new PageElementUpdateRequest(_this);
      pageElementUpdateRequest.setParams(_this.modalData);
      await pageElementUpdateRequest.fetch(function (content){
        _this.overlay=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.closeUploadModal();
        window.location.reload();
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
    getPieceByPriority(priority){
      let _this = this;

      let pageData = _this.pageData.find(el => el.priority === priority)
      if (pageData) return pageData;
    },
    async closeUploadModal(){
      let _this = this;

      await _this.getPageElement();
      _this.modalData = null;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0];
      _this.base64ImageSrc = URL.createObjectURL(file);
      _this.modalData.fileData = {
        base64:'',
        priority:1
      };
      _this.modalData.fileData.base64 = await _this.readAsDataURL(file);
    },
    deletePic() {
      this.storyData.base64 = null;
      this.base64ImageSrc = null;
    },
  },
  watch:{
    showUploadModal(nv,ov){
      console.log(nv)
    }
  },
  components: {
    // NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BModal,
    BInputGroup
  },
}
</script>

<style >
.modal-header > .close{
  display: none!important;
}
</style>
